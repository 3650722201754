<template>
  <RsModal
    class="modal-confirm-language"
    modal-window-classes="modal-window-edit-driver"
    @dismiss="closeModal"
  >
    <RsHeadline variant="h2">
      {{ $t('navbar.changeLanguage') }}
    </RsHeadline>
    <i18n-t
      tag="div"
      keypath="confirmChangeLanguage.description"
      class="mt-4 mb-8 text-gray-600"
    >
      <template #languageLabel>
        <span class="font-semibold uppercase italic">{{ newLanguage }}</span>
      </template>
    </i18n-t>
    <div class="grid grid-cols-2 gap-4">
      <RsButton
        id="gtm-driver-modal-confirm-language-no"
        variant="secondary"
        @click="closeModal"
      >
        {{ $t('common.no') }}
      </RsButton>
      <RsButton
        id="gtm-driver-modal-confirm-language-yes"
        @click="confirmAndClose"
      >
        <span class="text-white">{{ $t('common.yes') }}</span>
      </RsButton>
    </div>
  </RsModal>
</template>

<script setup lang="ts">
defineProps({
  newLanguage: {
    type: String,
    required: true,
  },
})

// TODO: emit validation
const emit = defineEmits(['commit'])

const closeModal = () => {
  emit('commit', { isConfirmed: false })
}
const confirmAndClose = () => {
  emit('commit', { isConfirmed: true })
}
</script>

<style scoped>
.modal-confirm-language {
  @apply items-center md:items-start;
}
:deep(.modal-window-edit-driver) {
  @apply !w-11/12 !max-w-md !h-auto !rounded-lg;
}

</style>
